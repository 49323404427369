import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function flavorpill(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/flavorpill-case1.jpg'
  const thumbAltText = 'flavorpill case study'
  const description = 'FlavorPill sought out a team with clear expertise with Rails and focus on agile methodologies. We teamed up not only to develop features and take over site maintenance, but also to promote an agile and efficient product development culture.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks helped Flavorpill achieve an 18-fold increase in web page concurrent users and sharply reduce response times.'

  return (
    <Layout
      location={props.location}
      title='Flavorpill'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <img src='/images/brands/flavorpill-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/flavorpill-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Arts, Events, Music
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  2 years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')}/>
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            FlavorPill is a social discovery engine and curated event marketplace that keeps you tapped into the cultural happenings around you. Through a potent mix of discovery, editorially and socially driven content, and intuitive user actions, FlavorPill keeps you up to date on the events you care about as well as what your friends are interested in doing.
          </p>
          <p>
            They’re active in culturally-steeped cities like New York City, Los Angeles, and San Francisco with plans to expand to Chicago, Miami, Seattle, among others. FlavorPill is the creator of culture blog Flavorwire which is consumed by millions of monthly readers. The New York Times has written that their "seal of approval is considered the equivalent of a papal blessing.”
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            Our team of full-stack developers wielded their expertise in Scrum methodologies to craft and implement an agile process, giving structure and consistency to feature development. We cleaned up legacy code making the developing environment more maintainable. And used the latest technologies to improve performance issues with the app, achieving an 18-fold increase in FlavorPill’s web page concurrent user capacity while simultaneously sharply reducing response times. What’s more, we refined their mailer, and were steadfast in our efforts to help launch a new, polished responsive homepage.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "They’re smart, focused, and passionate about writing and maintaining great code, and are constantly striving to improve the way we work together. They say they’re Ruby and agile, and they are, delivering every week."
              </div>
              <div className='quote-block__provider-title'>
                Mark Mangan
                <br />
                CPO &amp; Co-Founder
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/flavorpill-case2.jpg' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            The tightly integrated process we put in place is one that FlavorPill can rely on for the long-term. The one week Scrum sprints provided a space for product managers, designers, and developers to openly communicate. Our code base improvements led to faster dev time and a major performance boost. All these results contributed to the fact that FlavorPill was able to double its user base in just a few months. Further, as lead developer, we teamed up with FlavorPill’s Chief Product Officer to evaluate potential hires, thereby helping FlavorPill form a robust product development team.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['supremegolf', 'argos']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
